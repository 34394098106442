import React from 'react';
import { removeUndefined } from '@m2/blueprint-utils';
import { GetStaticPaths, GetStaticPropsContext } from 'next';
import styled from '@emotion/styled';
import { IGenericContentPageEntry } from '../contentful/types';
import { CAROUSEL_SECTION_TYPE_ID } from '../contentful';
import { defaultLocale as locale } from '../i18n/Locale';
import { contentful } from '../contentful/client';
import { Section } from '../components/common/sections';
import { PageLayout } from '../components/common/wrappers/PageLayout';
import { StyledSectionsWrapper } from '../components/common/wrappers/StyledSectionsWrapper';
import Custom404 from './404';
import { isPreviewMode } from '../config/utils';
import { getStaticPathsForGenericPages } from '../contentful/routes';
import { getPageForPath } from '../contentful/routes/getPageForPath';
import { SectionList } from '../components/common/sections/SectionList';

type IGenericPageParams = { slug: string[] };
interface IGenericPageProps {
	page: IGenericContentPageEntry | null;
}

export const getStaticPaths: GetStaticPaths<IGenericPageParams> = async () => {
	const paths = await getStaticPathsForGenericPages(contentful(), locale);

	return {
		paths,
		fallback: isPreviewMode(),
	};
};

export async function getStaticProps({
	params,
}: GetStaticPropsContext<IGenericPageParams>) {
	const page = await getPageForPath(params?.slug ?? '', locale);

	return {
		props: removeUndefined({
			page,
		}),
	};
}

const PublicationDate = ({ publicationDate }: { publicationDate?: string }) => {
	if (!publicationDate) {
		return null;
	}
	const date = new Date(publicationDate);
	const publicationDateStr = date.toLocaleString('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	});

	return (
		<StyledSection>
			<time dateTime={publicationDate}>
				DFW Airport, Texas, {publicationDateStr}
			</time>
		</StyledSection>
	);
};

const StyledSection = styled(Section)`
	padding-bottom: 0;
`;

const Page = ({ page }: IGenericPageProps) => {
	if (!page) {
		return <Custom404 />;
	}

	const { publicationDate } = page.fields;

	return (
		<PageLayout page={page}>
			<StyledSectionsWrapper
				resetOverflow={
					!page.fields.sections?.find(
						({ contentType }) => contentType === CAROUSEL_SECTION_TYPE_ID,
					)
				}
			>
				<PublicationDate publicationDate={publicationDate} />

				<SectionList sections={page.fields.sections} />
			</StyledSectionsWrapper>
		</PageLayout>
	);
};

export default Page;
